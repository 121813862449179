.taxationWrapper {
    .MuiSwitch-root {
        height: 29px ;
        padding: 0 0px 3px 0;
    }
}
.react-datepicker {
    z-index: 9999 !important;
}
.react-datepicker-popper {
    z-index:999;
}
.react-datepicker__navigation--previous{
    left: 10px;
    border-right-color: #000 !important;
}
.react-datepicker__navigation--next{
    right: 10px;
    border-left-color: #000 !important;
}
.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3 !important;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6 !important;
  cursor: default;
}
.alert-dialoge-root {
    .MuiBox-root {
        padding: 5px !important;
    }
    .alert-dialog-action {
        margin-top: 0 !important;
    }
    .alert-dialog-message {
        .MuiBox-root {
            padding: 0 !important;
        }
    }
    .MuiDialog-paperWidthSm {
        max-width: 350px !important;
    }
}